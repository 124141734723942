import React from "react";

const Home = () => (
  <>
    {/* main-slider-start */}
    <section className="main-slider-one">
      <div
        className="main-slider-one__carousel growim-owl__carousel--with-counter owl-carousel"
        data-owl-options='{

		"loop": true,

		"animateOut": "fadeOut",

		"animateIn": "fadeIn",

		"items": 1,

		"autoplay": true,

		"autoplayTimeout": 7000,

		"smartSpeed": 1000,

		"nav": true,

  "navText": ["<span class=\"flaticon-left-angle\"></span>","<span class=\"flaticon-right-angle\"></span>"],

		"dots": false,

		"margin": 0

	    }'
      >
        <div className="item">
          <div className="main-slider-one__item">
            <div
              className="main-slider-one__bg"
              style={{
                backgroundImage:
                  "url(/assets/images/backgrounds/slider-1-1.png)",
              }}
            />
            <div
              className="main-slider-one__shape-one"
              style={{
                backgroundImage:
                  "url(/assets/images/shapes/slider-1-shape-1.png)",
              }}
            />
            <div className="main-slider-one__shape-two">
              <img
                src="/assets/images/shapes/slider-1-shape-2.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-three">
              <img
                src="/assets/images/shapes/slider-1-shape-3.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-four">
              <img
                src="/assets/images/shapes/slider-1-shape-4.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-five">
              <img
                src="/assets/images/shapes/slider-1-shape-5.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="/assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="/assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-seven">
              <img
                src="/assets/images/shapes/slider-1-shape-7.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__report">
              <p className="main-slider-one__report__text">monthly traffic</p>
              <h5 className="main-slider-one__report__number">
                <span>+3.4%</span>
              </h5>
            </div>
            <div className="main-slider-one__content">
              <h5 className="main-slider-one__sub-title">go for advertising</h5>
              {/* slider-sub-title */}
              <h2 className="main-slider-one__title">Think Digital</h2>
              {/* slider-title */}
              <p className="main-slider-one__text">
                In today’s fast-paced world, digital advertising isn’t just an
                option—it’s a necessity. It’s how brands build relationships,
                inspire trust, and drive success.
              </p>
              {/* slider-text */}
              <div className="main-slider-one__btn">
                <a
                  className="growim-btn growim-btn--white"
                  href=""
                >
                  <span className="growim-btn__text">view services</span>
                  <span className="growim-btn__icon">
                    <i className="flaticon-up-right-arrow" />
                  </span>
                </a>
              </div>
              {/* slider-btn */}

              {/* video-btn */}
            </div>
          </div>
        </div>
        {/* item */}
        <div className="item">
          <div className="main-slider-one__item">
            <div
              className="main-slider-one__bg"
              style={{
                backgroundImage:
                  "url(/assets/images/backgrounds/slider-1-1.png)",
              }}
            />
            <div
              className="main-slider-one__shape-one"
              style={{
                backgroundImage:
                  "url(/assets/images/shapes/slider-1-shape-1.png)",
              }}
            />
            <div className="main-slider-one__shape-two">
              <img
                src="/assets/images/shapes/slider-1-shape-2.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-three">
              <img
                src="/assets/images/shapes/slider-1-shape-3.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-four">
              <img
                src="/assets/images/shapes/slider-1-shape-4.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-five">
              <img
                src="/assets/images/shapes/slider-1-shape-5.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="/assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="/assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-seven">
              <img
                src="/assets/images/shapes/slider-1-shape-7.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__report">
              <p className="main-slider-one__report__text">monthly traffic</p>
              <h5 className="main-slider-one__report__number">
                <span>+3.4%</span>
              </h5>
            </div>
            <div className="main-slider-one__content">
              <h5 className="main-slider-one__sub-title">go for advertising</h5>
              {/* slider-sub-title */}
              <h2 className="main-slider-one__title">Think Digital</h2>
              {/* slider-title */}
              <p className="main-slider-one__text">
                In today’s fast-paced world, digital advertising isn’t just an
                option—it’s a necessity. It’s how brands build relationships,
                inspire trust, and drive success.
              </p>
              {/* slider-text */}
              <div className="main-slider-one__btn">
                <a
                  className="growim-btn growim-btn--white"
                  href="services.html"
                >
                  <span className="growim-btn__text">view services</span>
                  <span className="growim-btn__icon">
                    <i className="flaticon-up-right-arrow" />
                  </span>
                </a>
              </div>
              {/* slider-btn */}

              {/* video-btn */}
            </div>
          </div>
        </div>
        {/* item */}
        <div className="item">
          <div className="main-slider-one__item">
            <div
              className="main-slider-one__bg"
              style={{
                backgroundImage:
                  "url(./assets/images/backgrounds/slider-1-1.png)",
              }}
            />
            <div
              className="main-slider-one__shape-one"
              style={{
                backgroundImage:
                  "url(./assets/images/shapes/slider-1-shape-1.png)",
              }}
            />
            <div className="main-slider-one__shape-two">
              <img
                src="./assets/images/shapes/slider-1-shape-2.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-three">
              <img
                src="./assets/images/shapes/slider-1-shape-3.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-four">
              <img
                src="./assets/images/shapes/slider-1-shape-4.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-five">
              <img
                src="./assets/images/shapes/slider-1-shape-5.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="./assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-six">
              <img
                src="./assets/images/shapes/slider-1-shape-6.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__shape-seven">
              <img
                src="./assets/images/shapes/slider-1-shape-7.png"
                alt="growim"
              />
            </div>
            <div className="main-slider-one__report">
              <p className="main-slider-one__report__text">monthly traffic</p>
              <h5 className="main-slider-one__report__number">
                <span>+3.4%</span>
              </h5>
            </div>
            <div className="main-slider-one__content">
              <h5 className="main-slider-one__sub-title">go for advertising</h5>
              {/* slider-sub-title */}
              <h2 className="main-slider-one__title">Think Digital</h2>
              {/* slider-title */}
              <p className="main-slider-one__text">
                In today’s fast-paced world, digital advertising isn’t just an
                option—it’s a necessity. It’s how brands build relationships,
                inspire trust, and drive success.
              </p>
              {/* slider-text */}
              <div className="main-slider-one__btn">
                <a
                  className="growim-btn growim-btn--white"
                  href="services.html"
                >
                  <span className="growim-btn__text">view services</span>
                  <span className="growim-btn__icon">
                    <i className="flaticon-up-right-arrow" />
                  </span>
                </a>
              </div>
              {/* slider-btn */}

              {/* video-btn */}
            </div>
          </div>
        </div>
        {/* item */}
      </div>
    </section>
    {/* main-slider-end */}

    {/* Feature End */}
    <section className="about-one">
      <div
        className="about-one__shape-one"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-1.png)",
        }}
      />
      <div
        className="about-one__shape-two"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-2.png)",
        }}
      />
      <div
        className="about-one__shape-three"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-3.png)",
        }}
      />
      <div
        className="about-one__shape-four"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-4.png)",
        }}
      />
      <div
        className="about-one__shape-five"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-5.png)",
        }}
      />
      <div
        className="about-one__shape-six"
        style={{
          backgroundImage: "url(assets/images/shapes/about-1-shape-6.png)",
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 wow fadeInLeft" data-wow-delay="100ms">
            <div className="about-one__content">
              <div className="sec-title text-left">
                <h6 className="sec-title__tagline">About Us</h6>
                {/* /.sec-title__tagline */}
                <h3 className="sec-title__title">
                  Welcome to Cloud Digital Solution
                </h3>
                {/* /.sec-title__title */}
              </div>
              {/* /.sec-title */}
              <p className="about-one__content__text">
                Transform your online presence with our expert digital marketing
                services. We're a leading agency dedicated to helping businesses
                thrive in the digital world. <br />
                Our team of experts will help you:
                <br />
                Elevate your brand with a robust online strategy <br />
                Drive more leads and sales with targeted marketing <br />
                Enhance your online reputation with engaging content <br />
                Stay ahead of the competition with data-driven insights
              </p>
              <ul className="about-one__list">
                <li>
                  <div className="about-one__list__icon">
                    <img
                      src="assets/images/shapes/about-1-icon-1.png"
                      alt="growim"
                    />
                  </div>
                  <div className="about-one__list__content">
                    <h3 className="about-one__list__number">94%</h3>
                    <p className="about-one__list__text">Sms Marketing</p>
                  </div>
                </li>
                <li>
                  <div className="about-one__list__icon">
                    <img
                      src="assets/images/shapes/about-1-icon-2.png"
                      alt="growim"
                    />
                  </div>
                  <div className="about-one__list__content">
                    <h3 className="about-one__list__number">65%</h3>
                    <p className="about-one__list__text">Cover Marketing</p>
                  </div>
                </li>
              </ul>
              <a className="growim-btn" href="about.html">
                <span className="growim-btn__text">About Us</span>
                <span className="growim-btn__icon">
                  <i className="flaticon-up-right-arrow" />
                </span>
              </a>
            </div>
            {/* /.why-choose-two__content */}
          </div>
          {/* /.col-lg-6 */}
          <div className="col-lg-6">
            <div
              className="about-one__image wow fadeInRight"
              data-wow-delay="200ms"
            >
              <div
                className="about-one__image__bg"
                style={{
                  backgroundImage:
                    "url(assets/images/shapes/about-image-bg.png)",
                }}
              />
              <img src="assets/images/resources/about-1-1.png" alt="growim" />
              <div
                className="about-one__image__clients wow fadeInUp"
                data-wow-delay="400ms"
              >
                <img
                  src="assets/images/resources/client-1-1.png"
                  alt="growim"
                />
                <img
                  src="assets/images/resources/client-1-2.png"
                  alt="growim"
                />
                <img
                  src="assets/images/resources/client-1-3.png"
                  alt="growim"
                />
                <img
                  src="assets/images/resources/client-1-4.png"
                  alt="growim"
                />
                <span>Happy Clients</span>
              </div>
            </div>
            {/* /.why-choose-two__image */}
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
    {/* /.about-one */}
    {/* Service Start */}
    <section className="service-one">
      <div
        className="service-one__shape-one"
        style={{
          backgroundImage: "url(assets/images/shapes/service-1-shape-1.png)",
        }}
      />
      <div
        className="service-one__shape-two"
        style={{
          backgroundImage: "url(assets/images/shapes/service-1-shape-2.png)",
        }}
      />
      <div
        className="service-one__shape-three"
        style={{
          backgroundImage: "url(assets/images/shapes/service-1-shape-3.png)",
        }}
      />
      <div
        className="service-one__shape-four"
        style={{
          backgroundImage: "url(assets/images/shapes/service-1-shape-4.png)",
        }}
      />
      <div
        className="service-one__shape-five"
        style={{
          backgroundImage: "url(assets/images/shapes/service-1-shape-5.png)",
        }}
      />
      <div className="container tabs-box">
        <div className="sec-title text-center">
          <h6 className="sec-title__tagline">our services</h6>
          {/* /.sec-title__tagline */}
          <h3 className="sec-title__title">
            Our Services Solve Any
            <br /> Business Problem
          </h3>
          {/* /.sec-title__title */}
        </div>
        {/* /.sec-title */}
        <ul className="list-unstyled tab-buttons service-one__tabs">
          <li data-tab="#building" className="tab-btn">
            Digital Marketing Strategy
          </li>
          <li data-tab="#SEO" className="tab-btn active-btn">
            SEO
          </li>
          <li data-tab="#PPC" className="tab-btn">
            PPC
          </li>
          <li data-tab="#branding" className="tab-btn">
            Social Media Marketing
          </li>
          <li data-tab="#creative" className="tab-btn">
            Content Marketing
          </li>
          <li data-tab="#ecommerce" className="tab-btn">
            Email Marketing
          </li>
        </ul>
        <div className="tabs-content">
          <div className="tab fadeInUp animated" id="building">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">Social Media Marketing</a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
          <div className="tab fadeInUp animated active-tab" id="SEO">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Video search optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
          <div className="tab fadeInUp animated" id="media">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Video search optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
          <div className="tab fadeInUp animated" id="branding">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Video search optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
          <div className="tab fadeInUp animated" id="creative">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Video search optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
          <div className="tab fadeInUp animated" id="ecommerce">
            <div className="row gutter-y-30">
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-1-hover.png)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-1.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Keyword Research &amp; Optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-2-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-2.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Video search optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-3-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-3.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        E-commerce SEO optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
              <div className="col-xl-3 col-md-6">
                <div className="service-one__item text-center">
                  <div className="service-one__item__wrapper">
                    <div
                      className="service-one__item__hover"
                      style={{
                        backgroundImage:
                          "url(assets/images/resources/service-1-4-hover.html)",
                      }}
                    />
                    <div
                      className="service-one__item__icon"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/service-icon-bg.png)",
                      }}
                    >
                      <img
                        src="assets/images/shapes/service-1-4.png"
                        alt="growim"
                      />
                    </div>
                    {/* /.service-icon */}
                    <h3 className="service-one__item__title">
                      <a href="service-details.html">
                        Link building optimization
                      </a>
                    </h3>
                    {/* /.service-title */}
                    <p className="service-one__item__text">
                      We can help you &amp; routine audits to determine an SEO
                      market
                    </p>
                    {/* /.service-text */}
                    <a
                      className="service-one__item__rm"
                      href="service-details.html"
                    >
                      <svg
                        viewBox="0 0 58 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                      </svg>
                      <i className="flaticon-up-right-arrow" />
                    </a>
                  </div>
                </div>
                {/* /.service-card-one */}
              </div>
              {/* /.item */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Service End */}
    <section className="why-choose-one">
      <div
        className="why-choose-one__shape-one"
        style={{
          backgroundImage: "url(assets/images/shapes/why-choose-1-shape-1.png)",
        }}
      />
      <div
        className="why-choose-one__shape-two"
        style={{
          backgroundImage: "url(assets/images/shapes/why-choose-1-shape-2.png)",
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="why-choose-one__image wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div
                className="why-choose-one__image__bg"
                style={{
                  backgroundImage:
                    "url(assets/images/shapes/why-choose-image-bg.png)",
                }}
              />
              <img
                src="assets/images/resources/why-choose-image-1.png"
                alt="growim"
              />
            </div>
            {/* /.why-choose-one__image */}
          </div>
          <div
            className="col-lg-6 d-flex align-items-center wow fadeInRight"
            data-wow-delay="200ms"
          >
            <div className="why-choose-one__content">
              <div className="sec-title text-left">
                <h6 className="sec-title__tagline">why choose us</h6>
                {/* /.sec-title__tagline */}
                <h3 className="sec-title__title">
                  Why You Need Digital Marketing
                </h3>
                {/* /.sec-title__title */}
              </div>
              {/* /.sec-title */}
              <p className="why-choose-one__text">
                <h>
                  Here are some industries that can benefit from digital
                  marketing:
                  <br />
                </h>
                E-commerce:
                <br /> Online shopping platforms, product marketplaces, and
                e-tailers.
                <br /> Healthcare:
                <br /> Hospitals, clinics, medical services, and wellness
                centers.
                <br /> Financial Services: Banks, insurance companies,
                investment firms, and financial advisors.
                <br />
                Education: Schools, universities, online courses, and
                educational resources. Travel and Tourism: Hotels, resorts,
                travel agencies, and tour operators.
                <br />
                Food and Beverage: Restaurants, cafes, food delivery services,
                and beverage companies.
                <br />
                Real Estate: Property developers, real estate agents, and rental
                services. Automotive: Car manufacturers, dealerships, and auto
                services. <br />
                Entertainment: Movies, music, gaming, and live events. Retail:
                Brick-and-mortar stores, shopping malls, and retail services.
                Technology: Software companies, IT services, and tech startups.
              </p>
              {/* /.why-choose-one__text */}
              <div className="why-choose-one__progress">
                <h4 className="why-choose-one__progress__title">
                  Traffic Increase
                </h4>
                <div className="why-choose-one__progress__bar">
                  <div
                    className="why-choose-one__progress__inner count-bar"
                    data-percent="90%"
                  >
                    <div className="why-choose-one__progress__number count-text">
                      90%
                    </div>
                  </div>
                </div>
              </div>
              {/* /.skills-item */}
              <div className="why-choose-one__progress">
                <h4 className="why-choose-one__progress__title">
                  Business Growth
                </h4>
                <div className="why-choose-one__progress__bar">
                  <div
                    className="why-choose-one__progress__inner count-bar"
                    data-percent="95%"
                  >
                    <div className="why-choose-one__progress__number count-text">
                      95%
                    </div>
                  </div>
                </div>
              </div>
              {/* /.skills-item */}
            </div>
            {/* /.why-choose-one__content */}
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
    {/* /.why-choose-one */}

    <section className="slide-text">
      <div className="slide-text__wrap wow fadeInUp" data-wow-delay="100ms">
        <ul className="slide-text__list list-unstyled">
          <li>BRANDING</li>
          <li>MARKETING</li>
          <li>CLIENT FEEDBACK</li>
          <li>Marketing Agency</li>
          <li>BRANDING</li>
          <li>MARKETING</li>
          <li>CLIENT FEEDBACK</li>
          <li>Marketing Agency</li>
          <li>BRANDING</li>
          <li>MARKETING</li>
          <li>CLIENT FEEDBACK</li>
          <li>Marketing Agency</li>
        </ul>
      </div>
    </section>
    <section className="blog-one">
      <div
        className="blog-one__shape-one"
        style={{
          backgroundImage: "url(./assets/images/shapes/blog-1-shape-1.png)",
        }}
      />
      <div
        className="blog-one__shape-two"
        style={{
          backgroundImage: "url(./assets/images/shapes/blog-1-shape-2.png)",
        }}
      />
      <div
        className="blog-one__shape-three"
        style={{
          backgroundImage: "url(./assets/images/shapes/blog-1-shape-3.png)",
        }}
      />
      <div
        className="blog-one__shape-four"
        style={{
          backgroundImage: "url(./assets/images/shapes/blog-1-shape-4.png)",
        }}
      />
      <div className="container">
        <div className="blog-one__top">
          <div className="sec-title text-left">
            <h6 className="sec-title__tagline">our NEWS</h6>
            {/* /.sec-title__tagline */}
            <h3 className="sec-title__title">Recent Blog Posts</h3>
            {/* /.sec-title__title */}
          </div>
          {/* /.sec-title */}
          <a className="growim-btn growim-btn--white" href="">
            <span className="growim-btn__text">View All News</span>
            <span className="growim-btn__icon">
              <i className="flaticon-up-right-arrow" />
            </span>
          </a>
        </div>
        <div
          className="blog-one__carousel growim-owl__carousel growim-owl__carousel--with-shadow growim-owl__carousel--basic-nav owl-carousel owl-theme"
          data-owl-options='{

				"items": 1,

				"margin": 30,

				"loop": false,

				"smartSpeed": 700,

				"nav": true,

				"navText": ["<span class=\"flaticon-long-arrow-left\"></span>","<span class=\"flaticon-long-arrow-right\"></span>"],

				"dots": false,

				"autoplay": false,

				"responsive": {

					"0": {

						"items": 1

					},

					"768": {

						"items": 2

					},

					"992": {

						"items": 3

					}

				}

			}'
        >
          <div className="item">
            <div
              className="blog-card wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="00ms"
            >
              <div className="blog-card__image">
                <img
                  src="./assets/images/blog/blog-1-1.png"
                  alt="Business strategy for marketing system"
                />
                <img
                  src="./assets/images/blog/blog-1-1.png"
                  alt="Business strategy for marketing system"
                />
                <a
                  href="blog-details-right.html"
                  className="blog-card__image__link"
                >
                  <span className="sr-only">
                    Business strategy for marketing system
                  </span>
                  {/* /.sr-only */}
                </a>
              </div>
              {/* /.blog-card__image */}
              <div className="blog-card__content">
                <div className="blog-card__meta">
                  <a className="blog-card__meta__cat" href="">
                    Business
                  </a>
                  <div className="blog-card__meta__date">March 24, 2024</div>
                  {/* /.blog-card__date */}
                </div>
                <h3 className="blog-card__title">
                  <a href="">Business strategy for marketing system</a>
                </h3>
                {/* /.blog-card__title */}
                <div className="blog-card__bottom">
                  <div className="blog-card__author">
                    <img
                      src="assets/images/blog/blog-author-1-1.jpg"
                      alt="growim"
                    />
                    <div className="blog-card__author__content">
                      <span>
                        <a href="">Admin</a>
                      </span>
                      Cody Fisher
                    </div>
                  </div>
                  <a href="" className="blog-card__link">
                    <i className="fas fa-arrow-right" />
                  </a>
                  {/* /.blog-card__link */}
                </div>
              </div>
              {/* /.blog-card__content */}
            </div>
            {/* /.blog-card */}
          </div>
          {/* /.item */}
          <div className="item">
            <div
              className="blog-card wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="blog-card__image">
                <img
                  src="assets/images/blog/blog-1-2.png"
                  alt="Why is Supply Chain Visibility So Important?"
                />
                <img
                  src="assets/images/blog/blog-1-2.png"
                  alt="Why is Supply Chain Visibility So Important?"
                />
                <a href="" className="blog-card__image__link">
                  <span className="sr-only">
                    Why is Supply Chain Visibility So Important?
                  </span>
                  {/* /.sr-only */}
                </a>
              </div>
              {/* /.blog-card__image */}
              <div className="blog-card__content">
                <div className="blog-card__meta">
                  <a className="blog-card__meta__cat" href="">
                    Corporate
                  </a>
                  <div className="blog-card__meta__date">April 16, 2024</div>
                  {/* /.blog-card__date */}
                </div>
                <h3 className="blog-card__title">
                  <a href="">Why is Supply Chain Visibility So Important?</a>
                </h3>
                {/* /.blog-card__title */}
                <div className="blog-card__bottom">
                  <div className="blog-card__author">
                    <img
                      src="assets/images/blog/blog-author-1-1.jpg"
                      alt="growim"
                    />
                    <div className="blog-card__author__content">
                      <span>
                        <a href="">Admin</a>
                      </span>
                      Cody Fisher
                    </div>
                  </div>
                  <a href="" className="blog-card__link">
                    <i className="fas fa-arrow-right" />
                  </a>
                  {/* /.blog-card__link */}
                </div>
              </div>
              {/* /.blog-card__content */}
            </div>
            {/* /.blog-card */}
          </div>
          {/* /.item */}
          <div className="item">
            <div
              className="blog-card wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="blog-card__image">
                <img
                  src="./assets/images/blog/blog-1-3.png"
                  alt="Discover a better way of redefining company goals"
                />
                <img
                  src="./assets/images/blog/blog-1-3.png"
                  alt="Discover a better way of redefining company goals"
                />
                <a href="" className="blog-card__image__link">
                  <span className="sr-only">
                    Discover a better way of redefining company goals
                  </span>
                  {/* /.sr-only */}
                </a>
              </div>
              {/* /.blog-card__image */}
              <div className="blog-card__content">
                <div className="blog-card__meta">
                  <a className="blog-card__meta__cat" href="">
                    Marketing
                  </a>
                  <div className="blog-card__meta__date">March 24, 2024</div>
                  {/* /.blog-card__date */}
                </div>
                <h3 className="blog-card__title">
                  <a href="">
                    Discover a better way of redefining company goals
                  </a>
                </h3>
                {/* /.blog-card__title */}
                <div className="blog-card__bottom">
                  <div className="blog-card__author">
                    <img
                      src="./assets/images/blog/blog-author-1-1.jpg"
                      alt="growim"
                    />
                    <div className="blog-card__author__content">
                      <span>
                        <a href="">Admin</a>
                      </span>
                      Cody Fisher
                    </div>
                  </div>
                  <a href="blog-details-right.html" className="blog-card__link">
                    <i className="fas fa-arrow-right" />
                  </a>
                  {/* /.blog-card__link */}
                </div>
              </div>
              {/* /.blog-card__content */}
            </div>
            {/* /.blog-card */}
          </div>
          {/* /.item */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
    {/* /.blog-one */}
  </>
);

export default Home;
