import React from "react";

const Blog = () => (
  <>
         
  <section className="page-header">
    <div className="page-header__bg" />
    {/* /.page-header__bg */}
    <div className="container">
      <h2 className="page-header__title">Blog </h2>
      <ul className="growim-breadcrumb list-unstyled">
        <li><a href="index.html">Home</a></li>
        <li><span>Blog </span></li>
      </ul>{/* /.thm-breadcrumb list-unstyled */}
    </div>{/* /.container */}
  </section>{/* /.page-header */}
  <section className="blog-one blog-one--page blog-one--standar">
    <div className="container">
      <div className="row gutter-y-60">
        <div className="col-lg-8">
          <div className="row gutter-y-30">
            <div className="col-md-12">
              <div className="blog-card-list  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                <div className="blog-card-list__image">
                  <img src="assets/images/blog/blog-l-1-1.jpg" alt="There are many variations of passages of available but majority" />
                  <img src="assets/images/blog/blog-l-1-1.jpg" alt="There are many variations of passages of available but majority" />
                  <a href="blog-details-right.html" className="blog-card-list__image__link" />
                  <div className="blog-card-list__date"><span>20</span>June</div>{/* /.blog-card__date */}
                </div>{/* /.blog-card__image */}
                <div className="blog-card-list__content">
                  <ul className="list-unstyled blog-card-list__meta">
                    <li><i className="flaticon-user-two" /><a href="blog-list-right.html">By Admin</a></li>
                    <li><i className="flaticon-comments-icon" />2 Comments</li>
                  </ul>{/* /.list-unstyled blog-card__meta */}
                  <h3 className="blog-card-list__title"><a href="blog-details-right.html">There are many variations of passages of available but majority</a></h3>{/* /.blog-card__title */}
                  <p className="blog-card-list__text">
                    Neque porro est qui dolorem ipsum quia quaed inventor veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit.
                  </p>{/* /.blog-card__text */}
                  <a href="blog-details-right.html" className="blog-card-list__link">
                    Read More
                  </a>
                </div>{/* /.blog-card-list__content */}
              </div>{/* /.blog-card-list */}
            </div>{/* /.col-md-12 */}
            <div className="col-md-12">
              <div className="blog-card-list  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                <div className="blog-card-list__image">
                  <img src="assets/images/blog/blog-l-1-2.jpg" alt="cupidatat nonproident, sunt in culpa qui officia deserunt" />
                  <img src="assets/images/blog/blog-l-1-2.jpg" alt="cupidatat nonproident, sunt in culpa qui officia deserunt" />
                  <a href="blog-details-right.html" className="blog-card-list__image__link" />
                  <div className="blog-card-list__date"><span>20</span>June</div>{/* /.blog-card__date */}
                </div>{/* /.blog-card__image */}
                <div className="blog-card-list__content">
                  <ul className="list-unstyled blog-card-list__meta">
                    <li><i className="flaticon-user-two" /><a href="blog-list-right.html">By Admin</a></li>
                    <li><i className="flaticon-comments-icon" />2 Comments</li>
                  </ul>{/* /.list-unstyled blog-card__meta */}
                  <h3 className="blog-card-list__title"><a href="blog-details-right.html">cupidatat nonproident, sunt in culpa qui officia deserunt</a></h3>{/* /.blog-card__title */}
                  <p className="blog-card-list__text">
                    Neque porro est qui dolorem ipsum quia quaed inventor veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit.
                  </p>{/* /.blog-card__text */}
                  <a href="blog-details-right.html" className="blog-card-list__link">
                    Read More
                  </a>
                </div>{/* /.blog-card-list__content */}
              </div>{/* /.blog-card-list */}
            </div>{/* /.col-md-12 */}
            <div className="col-md-12">
              <div className="blog-card-list  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="blog-card-list__image">
                  <img src="assets/images/blog/blog-l-1-3.jpg" alt="Why to Support Poor Leading Provider of Country Development" />
                  <img src="assets/images/blog/blog-l-1-3.jpg" alt="Why to Support Poor Leading Provider of Country Development" />
                  <a href="blog-details-right.html" className="blog-card-list__image__link" />
                  <div className="blog-card-list__date"><span>20</span>June</div>{/* /.blog-card__date */}
                </div>{/* /.blog-card__image */}
                <div className="blog-card-list__content">
                  <ul className="list-unstyled blog-card-list__meta">
                    <li><i className="flaticon-user-two" /><a href="blog-list-right.html">By Admin</a></li>
                    <li><i className="flaticon-comments-icon" />2 Comments</li>
                  </ul>{/* /.list-unstyled blog-card__meta */}
                  <h3 className="blog-card-list__title"><a href="blog-details-right.html">Why to Support Poor Leading Provider of Country Development</a></h3>{/* /.blog-card__title */}
                  <p className="blog-card-list__text">
                    Neque porro est qui dolorem ipsum quia quaed inventor veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit.
                  </p>{/* /.blog-card__text */}
                  <a href="blog-details-right.html" className="blog-card-list__link">
                    Read More
                  </a>
                </div>{/* /.blog-card-list__content */}
              </div>{/* /.blog-card-list */}
            </div>{/* /.col-md-12 */}
            <div className="col-md-12">
              <div className="blog-card-list  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                <div className="blog-card-list__image">
                  <img src="assets/images/blog/blog-l-1-4.jpg" alt="There are Many Variations Passages of Available Majority Place" />
                  <img src="assets/images/blog/blog-l-1-4.jpg" alt="There are Many Variations Passages of Available Majority Place" />
                  <a href="blog-details-right.html" className="blog-card-list__image__link" />
                  <div className="blog-card-list__date"><span>20</span>June</div>{/* /.blog-card__date */}
                </div>{/* /.blog-card__image */}
                <div className="blog-card-list__content">
                  <ul className="list-unstyled blog-card-list__meta">
                    <li><i className="flaticon-user-two" /><a href="blog-list-right.html">By Admin</a></li>
                    <li><i className="flaticon-comments-icon" />2 Comments</li>
                  </ul>{/* /.list-unstyled blog-card__meta */}
                  <h3 className="blog-card-list__title"><a href="blog-details-right.html">There are Many Variations Passages of Available Majority Place</a></h3>{/* /.blog-card__title */}
                  <p className="blog-card-list__text">
                    Neque porro est qui dolorem ipsum quia quaed inventor veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit.
                  </p>{/* /.blog-card__text */}
                  <a href="blog-details-right.html" className="blog-card-list__link">
                    Read More
                  </a>
                </div>{/* /.blog-card-list__content */}
              </div>{/* /.blog-card-list */}
            </div>{/* /.col-md-12 */}
            <div className="col-md-12">
              <ul className="post-pagination blog-page__pagination text-left">
                <li>
                  <a className="prev" href="blog-list-right.html"><span className="flaticon-left-angle" /></a>
                </li>
                <li>
                  <a className="current" href="blog-list-right.html">01</a>
                </li>
                <li>
                  <a href="blog-list-right.html">02</a>
                </li>
                <li>
                  <a href="blog-list-right.html">03</a>
                </li>
                <li>
                  <a className="next" href="blog-list-right.html"><span className="flaticon-right-angle" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>{/* /.col-lg-8 */}
        <div className="col-lg-4">
          <div className="sidebar">
            <aside className="widget-area">
              <div className="sidebar__single">
                <h4 className="sidebar__title">Search Here</h4>{/* /.sidebar__title */}
                <form action="#" className="sidebar__search">
                  <input type="text" placeholder="search here" />
                  <button type="submit" aria-label="search submit">
                    <span><i className="flaticon-search" /></span>
                  </button>
                </form>{/* /.sidebar__search */}
              </div>{/* /.sidebar__single */}
              <div className="sidebar__single">
                <h4 className="sidebar__title">Latest posts</h4>{/* /.sidebar__title */}
                <ul className="sidebar__posts list-unstyled">
                  <li className="sidebar__posts__item">
                    <div className="sidebar__posts__image">
                      <img src="assets/images/blog/lp-1-1.jpg" alt="growim" />
                    </div>{/* /.sidebar__posts__image */}
                    <div className="sidebar__posts__content">
                      <p className="sidebar__posts__meta"><a href="blog-list-right.html"><i className="flaticon-user-two" /> by Admin</a></p>{/* /.sidebar__posts__date */}
                      <h4 className="sidebar__posts__title"><a href="blog-details-right.html">There are many vario ns of passages of</a></h4>{/* /.sidebar__posts__title */}
                    </div>{/* /.sidebar__posts__content */}
                  </li>
                  <li className="sidebar__posts__item">
                    <div className="sidebar__posts__image">
                      <img src="assets/images/blog/lp-1-2.jpg" alt="growim" />
                    </div>{/* /.sidebar__posts__image */}
                    <div className="sidebar__posts__content">
                      <p className="sidebar__posts__meta"><a href="blog-list-right.html"><i className="flaticon-user-two" /> by Admin</a></p>{/* /.sidebar__posts__date */}
                      <h4 className="sidebar__posts__title"><a href="blog-details-right.html">What’s the Holding Back the Solution</a></h4>{/* /.sidebar__posts__title */}
                    </div>{/* /.sidebar__posts__content */}
                  </li>
                  <li className="sidebar__posts__item">
                    <div className="sidebar__posts__image">
                      <img src="assets/images/blog/lp-1-3.jpg" alt="growim" />
                    </div>{/* /.sidebar__posts__image */}
                    <div className="sidebar__posts__content">
                      <p className="sidebar__posts__meta"><a href="blog-list-right.html"><i className="flaticon-user-two" /> by Admin</a></p>{/* /.sidebar__posts__date */}
                      <h4 className="sidebar__posts__title"><a href="blog-details-right.html">Get deep knowledge of your beauty</a></h4>{/* /.sidebar__posts__title */}
                    </div>{/* /.sidebar__posts__content */}
                  </li>
                </ul>{/* /.sidebar__posts list-unstyled */}
              </div>{/* /.sidebar__single */}
              <div className="sidebar__single">
                <h4 className="sidebar__title">Categories</h4>{/* /.sidebar__title */}
                <ul className="sidebar__categories list-unstyled">
                  <li><a href="blog-details-right.html">Pay-Per-Click (PPC)</a>(6)</li>
                  <li><a href="blog-details-right.html">Social Media Marketing</a>(6)</li>
                  <li><a href="blog-details-right.html">Email Marketing</a>(6)</li>
                  <li><a href="blog-details-right.html">Electrotherapy</a>(6)</li>
                  <li><a href="blog-details-right.html">Affiliate Marketing</a>(6)</li>
                </ul>{/* /.sidebar__categories list-unstyled */}
              </div>{/* /.sidebar__single */}
              <div className="sidebar__single">
                <h4 className="sidebar__title">Tags</h4>{/* /.sidebar__title */}
                <div className="sidebar__tags">
                  <a href="blog-details-right.html">Marketing</a>
                  <a href="blog-details-right.html">Agency</a>
                  <a href="blog-details-right.html">Search Engine</a>
                  <a href="blog-details-right.html">Influencer</a>
                  <a href="blog-details-right.html">Advertising</a>
                  <a href="blog-details-right.html">Branding</a>
                </div>{/* /.sidebar__tags */}
              </div>{/* /.sidebar__single */}
              <div className="sidebar__single">
                <h4 className="sidebar__title">Comments</h4>{/* /.sidebar__title */}
                <ul className="sidebar__comments list-unstyled">
                  <li className="sidebar__comments__item">
                    <div className="sidebar__comments__icon">
                      <i className="flaticon-comment-three" />
                    </div>{/* /.sidebar__comments__icon */}
                    <h6 className="sidebar__comments__title">Neque porro est qui dolorem ipsum quia quaed inventor</h6>{/* /.sidebar__comments__title */}
                  </li>
                  <li className="sidebar__comments__item">
                    <div className="sidebar__comments__icon">
                      <i className="flaticon-comment-three" />
                    </div>{/* /.sidebar__comments__icon */}
                    <h6 className="sidebar__comments__title">Neque porro est qui dolorem ipsum quia quaed inventor</h6>{/* /.sidebar__comments__title */}
                  </li>
                  <li className="sidebar__comments__item">
                    <div className="sidebar__comments__icon">
                      <i className="flaticon-comment-three" />
                    </div>{/* /.sidebar__comments__icon */}
                    <h6 className="sidebar__comments__title">Neque porro est qui dolorem ipsum quia quaed inventor</h6>{/* /.sidebar__comments__title */}
                  </li>
                </ul>{/* /.sidebar__comments list-unstyled */}
              </div>{/* /.sidebar__single */}
            </aside>{/* /.widget-area */}
          </div>{/* /.sidebar */}
        </div>{/* /.col-lg-4 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
  </section>{/* /.blog-one blog-one--page */}



  
  
  </>
);

export default Blog;
