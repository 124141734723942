import React from "react";

const Contact = () => (
  <>
    <div>
      <section className="page-header">
        <div className="page-header__bg" />
        {/* /.page-header__bg */}
        <div className="container">
          <h2 className="page-header__title">Contact Us</h2>
          <ul className="growim-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Contact Us</span>
            </li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
        </div>
        {/* /.container */}
      </section>
      {/* /.page-header */}
      <section className="contact-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-two__image">
                <img
                  src="assets/images/shapes/contact-page-1.png"
                  alt="growim"
                />
                <div className="contact-two__image__video">
                  <img
                    src="assets/images/resources/contact-page-video.jpg"
                    alt="growim"
                  />
                  <a
                    href="https://www.youtube.com/watch?v=h9MbznbxlLc"
                    className="video-popup"
                  >
                    <span className="ripple" />
                    <i className="fa fa-play" />
                  </a>
                </div>
              </div>
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="contact-two__content">
                <h3 className="contact-two__title">
                  Get in Touch with Cloud Digital Solution
                </h3>
                <p className="contact-two__text">
                  Have a question, project, or just want to say hello? We'd love
                  to hear from you! Please fill out the form below and we'll get
                  back to you promptly.
                </p>
                <form
                  className="contact-two__form contact-form-validated form-one wow fadeInUp"
                  data-wow-duration="1500ms"
                  action=""
                >
                  <h4 className="contact-two__form__title">Get In touch</h4>
                  <div className="form-one__group">
                    <div className="form-one__control">
                      <input type="text" name="name" placeholder="Name" />
                    </div>
                    {/* /.form-one__control */}
                    <div className="form-one__control">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                    <div className="form-one__control">
                      <input
                        type="text"
                        name="address"
                        placeholder="Write  Address Here"
                      />
                    </div>

                    {/* /.form-one__control */}
                    <div className="form-one__control form-one__control--full">
                      <textarea
                        name="message"
                        placeholder="Write Message . . ."
                        defaultValue={""}
                      />
                      {/* /# */}
                    </div>
                    {/* /.form-one__control */}
                    <div className="form-one__control form-one__control--full">
                      <button className="growim-btn" type="submit">
                        <span className="growim-btn__text">Send Message</span>
                        <span className="growim-btn__icon">
                          <i className="flaticon-up-right-arrow" />
                        </span>
                      </button>
                    </div>
                    {/* /.form-one__control */}
                  </div>
                  {/* /.form-one__group */}
                </form>
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.contact-two */}
      <section className="google-map">
        <div className="google-map__contact">
          <iframe
            title="template google map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
            className="map__contact"
            allowFullScreen
          />
        </div>
        {/* /.google-map */}
        <div className="google-map__wrapper">
          <div className="container">
            <div className="google-map__info">
              <h3 className="google-map__info__title">Contact Info</h3>
              <ul className="list-unstyled google-map__info__list">
                <li>
                  <i className="flaticon-pin" />
                  Pune
                </li>
                <li>
                  <i className="flaticon-phone" />
                  <a href="tel:+61086660112">+91 93939393933</a>
                </li>
                <li>
                  <i className="flaticon-email" />
                  <a href="mailto:info@gmail.com">info@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* /.google-map */}
     
    </div>
  </>
);

export default Contact;
