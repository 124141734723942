import React from "react";

const Services = () => (
  <>
    <div>
      <section className="page-header">
        <div className="page-header__bg" />
        {/* /.page-header__bg */}
        <div className="container">
          <h2 className="page-header__title">Our Service</h2>
          <ul className="growim-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Our Service</span>
            </li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
        </div>
        {/* /.container */}
      </section>
      {/* /.page-header */}
      {/* Service Start */}
      <section className="service-six">
        <div className="container">
          <div className="row gutter-y-30">
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-1.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-1.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">
                      Search Engine Optimization
                    </a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            {/* /.item */}
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-2.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-2.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">
                      Pay-Per-Click Advertising (PPC)
                    </a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            {/* /.item */}
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-3.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-3.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Social Media Marketing</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            {/* /.item */}

            {/* /.item */}
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-5.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-5.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Email Marketing</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            {/* /.item */}
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-6.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-6.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Video Marketing</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            {/* /.item */}
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-4.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-4.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">
                      Digital Marketing Strategy
                    </a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-4.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-4.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Influencer Marketing</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-4.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-4.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Affiliate Marketing</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-six__item text-center">
                <div className="service-six__item__wrapper">
                  <div className="service-six__item__image">
                    <img
                      src="assets/images/resources/service-6-4.jpg"
                      alt="growim"
                    />
                  </div>
                  <div
                    className="service-six__item__icon"
                    style={{
                      backgroundImage:
                        "url(assets/images/shapes/service-6-icon-bg.png)",
                    }}
                  >
                    <img
                      src="assets/images/shapes/service-6-4.png"
                      alt="growim"
                    />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-six__item__title">
                    <a href="service-details.html">Analytics and Reporting</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-six__item__text">
                    We can help you &amp; routine audits to determine an SEO
                  </p>
                  {/* /.service-text */}
                  <a
                    className="service-six__item__rm"
                    href="service-details.html"
                  >
                    <svg viewBox="0 0 58 66" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.46 1.07519C27.646 -0.206291 30.354 -0.206292 32.54 1.07519L54.54 13.9717C56.6833 15.2281 58 17.5263 58 20.0106V45.9894C58 48.4737 56.6833 50.7719 54.54 52.0283L32.54 64.9248C30.354 66.2063 27.646 66.2063 25.46 64.9248L3.45996 52.0283C1.31675 50.7719 0 48.4737 0 45.9894V20.0106C0 17.5263 1.31675 15.2281 3.45996 13.9717L25.46 1.07519Z" />
                    </svg>
                    <i className="flaticon-up-right-arrow" />
                  </a>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>
        </div>
      </section>

   
    </div>
  </>
);

export default Services;
