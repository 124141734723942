import React from "react";
import { Link, NavLink } from "react-router-dom";


const Footer = () => (
  <>
    
    
  <footer className="main-footer">
    <div className="main-footer__bg" style={{backgroundImage: 'url(assets/images/shapes/footer-bg-1-1.png)'}} />
    <div className="main-footer__shape" style={{backgroundImage: 'url(assets/images/shapes/footer-1-shape-1.png)'}} />
    {/* /.main-footer__bg */}
    <div className="main-footer__top">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xl-4">
            <div className="footer-widget footer-widget--about">
        
              <div className="footer-widget__social">
                <p className="footer-widget__social__text">Follow on</p>
                <a href="https://facebook.com/">
                  <i className="fab fa-facebook-f" aria-hidden="true" />
                  <span className="sr-only">Facebook</span>
                </a>
                <a href="https://twitter.com/">
                  <i className="fab fa-twitter" aria-hidden="true" />
                  <span className="sr-only">Twitter</span>
                </a>
                <a href="https://www.linkedin.com/">
                  <i className="fab fa-linkedin-in" aria-hidden="true" />
                  <span className="sr-only">Linkedin</span>
                </a>
                <a href="https://youtube.com/">
                  <i className="fab fa-youtube" aria-hidden="true" />
                  <span className="sr-only">Youtube</span>
                </a>
              </div>{/* /.footer-widget__social */}
            </div>{/* /.footer-widget */}
          </div>{/* /.col-md-6 */}
          <div className="col-md-6 col-xl-2">
            <div className="footer-widget">
              <h2 className="footer-widget__title">Quick Link</h2>{/* /.footer-widget__title */}
              <ul className="list-unstyled footer-widget__links">
                <li><a href=""> About</a></li>
                <li><a href="">Contact Us</a></li>
                <li><a href="">Blog</a></li>
              
              </ul>{/* /.list-unstyled footer-widget__links */}
            </div>{/* /.footer-widget */}
          </div>{/* /.col-md-6 */}
          <div className="col-md-6 col-xl-2">
            <div className="footer-widget footer-widget--links">
              <h2 className="footer-widget__title">our services</h2>{/* /.footer-widget__title */}
              <ul className="list-unstyled footer-widget__links">
                <li><a href="">digital marketing</a></li>
                <li><a href="">web development</a></li>
                <li><a href="">SEO optimized</a></li>
                <li><a href="">App Development</a></li>
                <li><a href="">Email Marketing</a></li>
              </ul>{/* /.list-unstyled footer-widget__links */}
            </div>{/* /.footer-widget */}
          </div>{/* /.col-md-6 */}
          <div className="col-md-6 col-xl-4">
            <div className="footer-widget footer-widget--contact">
              <h2 className="footer-widget__title">contact us</h2>{/* /.footer-widget__title */}
              <ul className="list-unstyled footer-widget__info">
                <li><i className="flaticon-pin" />Pune</li>
                <li><i className="flaticon-phone" /><a href="tel:+">+91919191919</a></li>
                <li><i className="flaticon-email" /><a href="mailto:info@gmail.com">info@gmail.com</a></li>
              </ul>{/* /.list-unstyled */}
            </div>{/* /.footer-widget */}
          </div>{/* /.col-md-6 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </div>{/* /.main-footer__top */}
    <div className="main-footer__bottom">
      <div className="container">
        <div className="main-footer__bottom__inner">
          <p className="main-footer__copyright">
            © Copyright <span className="dynamic-year" /> by Cloud Digital Solution
          </p>
          <ul className="main-footer__bottom__menu">
            <li><a href="checkout.html">Terms &amp; Conditions</a></li>
            <li><a href="contact.html">Privacy Policy</a></li>
          </ul>
        </div>{/* /.main-footer__inner */}
      </div>{/* /.container */}
    </div>{/* /.main-footer__bottom */}
  </footer>{/* /.main-footer */}
  {/* /.page-wrapper */}
  
   <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler" />
        {/* /.mobile-nav__overlay */}
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i className="fa fa-times" />
          </span>
          <div className="logo-box">
            <a href="index.html" aria-label="logo image">
              <img src="assets/images/logo-light.png" width={155} alt />
            </a>
          </div>
          {/* /.logo-box */}
          <div className="mobile-nav__container" />
          {/* /.mobile-nav__container */}
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope" />
              <a href="mailto:needhelp@growim.com">needhelp@growim.com</a>
            </li>
            <li>
              <i className="fa fa-phone-alt" />
              <a href="tel:666-888-0000">666 888 0000</a>
            </li>
          </ul>
          {/* /.mobile-nav__contact */}
          <div className="mobile-nav__social">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" aria-hidden="true" />
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" aria-hidden="true" />
              <span className="sr-only">Twitter</span>
            </a>
            <a href="https://www.linkedin.com/">
              <i className="fab fa-linkedin-in" aria-hidden="true" />
              <span className="sr-only">Linkedin</span>
            </a>
            <a href="https://youtube.com/">
              <i className="fab fa-youtube" aria-hidden="true" />
              <span className="sr-only">Youtube</span>
            </a>
          </div>
          {/* /.mobile-nav__social */}
        </div>
        {/* /.mobile-nav__content */}
      </div>
      {/* /.mobile-nav__wrapper */}
      <div className="search-popup">
        <div className="search-popup__overlay search-toggler" />
        {/* /.search-popup__overlay */}
        <div className="search-popup__content">
          <form
            role="search"
            method="get"
            className="search-popup__form"
            action="#"
          >
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="growim-btn"
            >
              <span className="growim-btn__text">
                <i className="flaticon-search" />
              </span>
            </button>
          </form>
        </div>
        {/* /.search-popup__content */}
      </div>
      {/* /.search-popup */}
      {/* Sidebar One Start */}
      <aside className="sidebar-one">
        <div className="sidebar-one__overlay" />
        {/* /.siderbar-ovarlay */}
        <div className="sidebar-one__content">
          <div className="sidebar-one__close">
            <i className="flaticon-close" />
          </div>
          {/* /.siderbar-close */}
          <div className="sidebar-one__logo">
            <a href="index.html" aria-label="logo image">
              <img
                src="assets/images/logo-2-light.png"
                alt="growim"
                width={160}
              />
            </a>
          </div>
          {/* /.sidebar-one__logo-box */}
          <p className="sidebar-one__text">
            Mauris ut enim sit amet lacus ornare ullamcor. Praesent placerat
            nequ puru rhoncu tincidunt odio ultrices. Sed feugiat feugiat felis.
          </p>
          <h4 className="sidebar-one__title">Contact Info:</h4>
          <ul className="sidebar-one__info">
            <li>
              <span className="fas fa-map-marker-alt" />
              85 Ketch Harbour RoadBensalem, PA 19020
            </li>
            <li>
              <span className="fas fa-envelope" />
              <a href="mailto:needhelp@company.com">needhelp@company.com</a>
            </li>
            <li>
              <span className="fas fa-phone-alt" />
              <a href="tel:09969569535">099 695 695 35</a>
            </li>
          </ul>
          <div className="sidebar-one__social">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" aria-hidden="true" />
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" aria-hidden="true" />
              <span className="sr-only">Twitter</span>
            </a>
            <a href="https://www.linkedin.com/">
              <i className="fab fa-linkedin-in" aria-hidden="true" />
              <span className="sr-only">Linkedin</span>
            </a>
            <a href="https://youtube.com/">
              <i className="fab fa-youtube" aria-hidden="true" />
              <span className="sr-only">Youtube</span>
            </a>
          </div>
          {/* /sidebar-one__socila */}
          <h4 className="sidebar-one__title">Newsletter:</h4>
          <form
            action="#"
            data-url="MAILCHIMP_FORM_URL"
            className="sidebar-one__newsletter mc-form"
          >
            <input type="text" name="EMAIL" placeholder="Email address" />
            <button type="submit" className="flaticon-paper-plan">
              <span className="sr-only">submit</span>
              {/* /.sr-only */}
            </button>
          </form>
          {/* /.footer-widget__newsletter mc-form */}
        </div>
        {/* /.sidebar__content */}
      </aside>
      {/* Sidebar One Start */}
      <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
        <span className="scroll-to-top__text">back top</span>
        <span className="scroll-to-top__wrapper">
          <span className="scroll-to-top__inner" />
        </span>
      </a>




   
  </>
);

export default Footer;
