import React from "react";
import { Link, NavLink } from "react-router-dom";


const Header = () => {
  return (
    <>
      <div>
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle" />
            </div>
          </div>
          <div className="site-mobile-menu-body" />
        </div>{" "}
        {/* .site-mobile-menu */}
        <div className="site-navbar-wrap">
        
          <div className="site-navbar site-navbar-target js-sticky-header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-4">
                  <img
                    src="/assets/images/clouddigial.png"
                    alt="Cloud Digital" height={75}
                  />
                </div>
                <div className="col-8">
                  <nav className="site-navigation text-right" role="navigation">
                    <div className="container">
                      <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                        <a
                          href="#"
                          className="site-menu-toggle js-menu-toggle text-white"
                        >
                          <span className="icon-menu h3" />
                        </a>
                      </div>
                      <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                        <li>
                          <Link
                            to="/"
                            exact
                            activeClassName="active"
                            className="nav-link"
                          >
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about"
                            activeClassName="active"
                            className="nav-link"
                          >
                            About
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/services"
                            activeClassName="active"
                            className="nav-link"
                          >
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/blog"
                            activeClassName="active"
                            className="nav-link"
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact"
                            activeClassName="active"
                            className="nav-link"
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
